










import { Vue, Component, Prop } from 'vue-property-decorator';

import { CarouselBreakpoint } from '@/app_code/CarouselBreakpoint';

import { Listing } from 'client-website-ts-library/types/Listing';

import Cycler from './Cycler.vue';
import CyclerItem from './CyclerItem.vue';
import ListingCard from '../ListingCard.vue';

@Component({
  components: {
    Cycler,
    CyclerItem,
    ListingCard,
  },
})
export default class ListingCardCycler extends Vue {
  @Prop()
  private readonly listings!: Listing[];

  private breakpoints: CarouselBreakpoint[] = [
    {
      breakpoint: 0,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1120,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 5,
      },
    },
  ];
}
