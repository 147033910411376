






























import { Component, Prop, Vue } from 'vue-property-decorator';
import VideoPlayerSource from '@/app_code/VideoPlayerSource';

@Component
export default class VideoBanner extends Vue {
  @Prop({ default: false })
  private loop!: boolean;

  @Prop({ default: false })
  private autoplay!: boolean;

  @Prop()
  private readonly ratio!: number;

  @Prop()
  private readonly poster!: string;

  @Prop()
  private readonly video!: VideoPlayerSource;

  @Prop({ default: '' })
  private animateText!: string;

  mounted() {
    // ON PLAY AND ON STOP EVENTS
    setTimeout(() => {
      this.animateText = 'home-cta__animate';
    }, 6000);

    setInterval(() => {
      this.animateText = '';
      setTimeout(() => {
        this.animateText = 'home-cta__animate';
      }, 12000);
    }, 114000);
  }
}
